@import '../../styles/settings';

.Avatar {
  display: inline-block;
  &__image {
    vertical-align: middle;
    object-fit: cover;
    &--small {
      min-width: 38px;
      width: 38px;
      height: 38px;
      background: #dfe5e7;
      border-radius: 50%;
    }
    &--medium {
      width: 52px;
      height: 52px;
      @media (max-width: 1023.98px) {
        width: 34px;
        height: 34px;
      }
    }
    &--large {
      min-width: 70px;
      width: 70px;
      height: 70px;
      @media (max-width: 1023.98px) {
        min-width: 50px;
        width: 50px;
        height: 50px;
      }
    }
    &--xlarge {
      width: 125px;
      height: 125px;
    }
  }
  &.is-offline,
  &.is-online {
    position: relative;
    html[dir='ltr'] & {
      margin-right: 10px;
    }
    html[dir='rtl'] & {
      margin-left: 10px;
    }
    &:after {
      content: '';
      position: absolute;
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      bottom: 0;
      html[dir='ltr'] & {
        right: 0;
      }
      html[dir='rtl'] & {
        left: 0;
      }
    }
  }
  &.is-offline:after {
    background: #a7a7a7;
  }
  &.is-online:after {
    background: #4acc48;
  }
  &__lg {
    &.is-offline,
    &.is-online {
      &:after {
        width: 12px;
        height: 12px;
        bottom: 8%;
        right: 8%;
        html[dir='ltr'] & {
          right: 8%;
        }
        html[dir='rtl'] & {
          left: 8%;
        }
      }
    }
  }
}
