@import '../../styles/settings';

.autocomplete-dropdown-container {
  top: 100%;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.1) 0px 4px 11px;
  margin-bottom: 8px;
  margin-top: 8px;
  position: absolute;
  width: 100%;
  z-index: 10;
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
  font-weight: 500;
}
.suggestion-item,
.suggestion-item--active {
  color: rgb(29, 29, 29);
  cursor: default;
  display: block;
  font-size: inherit;
  width: 100%;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  padding: 8px 12px;
  background: rgb(255, 255, 255);
}
.suggestion-item:hover,
.suggestion-item--active:hover {
  background-color: rgba(2, 130, 131, 0.1);
}
