/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --primary: 38, 50, 56; /* #263238 */
    --secondary: 239, 57, 53; /* #EF3935 */
    --muted: 102, 102, 102; /* #666 */
    --slate-50: 248, 248, 248; /* #F8F8F8 */
    --slate-200: 228, 228, 228; /* #E4E4E4 */
    --slate-300: 231, 231, 231; /* #E7E7E7 */
    --default: 0, 0, 0; /* #000  used for body font color */
    --gray-900: 38, 50, 56; /* #263238 */
  }
  .teal {
    --rmdp-primary-teal: #151817;
    --rmdp-today-teal: #151817;
    --rmdp-hover-teal: #151817;
  }

  @font-face {
    font-family: 'AvenirLTStd';
    src: url('../assets/fonts/AvenirLTStd-Light.woff2') format('woff2'),
      url('../assets/fonts/AvenirLTStd-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'AvenirLTStd';
    src: url('../assets/fonts/AvenirLTStd-Regular.woff2') format('woff2'),
      url('../assets/fonts/AvenirLTStd-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'AvenirLTStd';
    src: url('../assets/fonts/AvenirLTStd-Medium.woff2') format('woff2'),
      url('../assets/fonts/AvenirLTStd-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'AvenirLTStd';
    src: url('../assets/fonts/AvenirLTStd-Bold.woff2') format('woff2'),
      url('../assets/fonts/AvenirLTStd-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'AvenirLTStd';
    src: url('../assets/fonts/AvenirLTStd-Extrabold.woff2') format('woff2'),
      url('../assets/fonts/AvenirLTStd-Extrabold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  body {
    @apply bg-white;
  }
}

@layer components {
  .btn {
    @apply border-2 text-xs px-4 py-[15px] focus:outline-none whitespace-nowrap inline-flex items-center justify-center text-center uppercase transition-all leading-3 rounded-[10px];
  }
  .btn-primary {
    @apply font-semibold border-primary bg-primary text-white hover:bg-[#222] hover:border-[#222];
  }
  .btn-outline-primary {
    @apply font-bold border-primary text-primary hover:border-primary hover:bg-primary hover:text-white;
  }
  .btn-secondary {
    @apply font-semibold border-secondary bg-secondary text-white hover:bg-[#fe201c] hover:border-secondary;
  }
  .btn-outline-secondary {
    @apply font-bold border-secondary bg-white text-secondary hover:border-secondary hover:bg-secondary hover:text-white;
  }
  .btn-disabled {
    @apply border-[#808080] bg-[#808080] text-white pointer-events-none;
  }
  .form-control {
    @apply bg-white font-medium border border-[#DBDBDB] text-black text-sm block w-full px-4 py-3 leading-[1.429] focus:border-primary focus:outline-none placeholder-[#666] rounded-[10px];
  }
  .form-control:disabled,
  .form-control.disabled {
    @apply bg-[#EAEAEA];
  }
  .container {
    max-width: 1232px !important; /* 1200 + 32 */
  }
  html[dir='rtl'] {
    text-align: right;
  }
  .custom-radio input {
    @apply hidden;
  }

  .custom-radio label {
    @apply inline-block relative text-sm cursor-pointer ltr:pl-8 rtl:pr-8 duration-500 before:duration-500 before:bg-white before:rounded-full before:w-5 before:h-5 before:bg-clip-content before:p-[3px] before:border-2 before:border-muted before:inline-block before:absolute before:top-0 ltr:before:left-0 rtl:before:right-0;
  }

  .custom-radio input:checked + label {
    @apply before:bg-primary before:border-primary;
  }

  .custom-checkbox label {
    @apply inline-block relative text-sm leading-[21px] cursor-pointer ltr:pl-8 rtl:pr-8 duration-500 before:duration-500 before:bg-white before:w-5 before:h-5 before:border before:border-muted before:inline-block before:absolute before:top-0 ltr:before:left-0 rtl:before:right-0 after:absolute after:top-1.5 ltr:after:left-1.5 rtl:after:right-1.5 after:border-l-2 after:border-b-2 after:border-gray-800 after:w-2.5 after:h-1.5 after:opacity-0 after:-rotate-45 after:rounded-[1px];
  }
}
[type='checkbox'],
[type='radio'] {
  display: none;
}
.custom-checkbox [type='checkbox']:checked + label:after {
  opacity: 1;
}

/* login */
.custom-select .select__control {
  border: none;
  padding: 0;
  min-height: 19px;
  height: 19px;
  outline: none;
}
.custom-select .select__value-container {
  padding: 0;
  line-height: 19px;
  outline: none;
}
.custom-select .select__indicators,
.select__indicator {
  padding: 0;
}
.custom-select .select__dropdown-indicator {
  padding: 0;
}
.custom-select .select__indicator-separator {
  display: none;
}
.custom-select .select__dropdown-indicator svg {
  display: none;
}
.custom-select .select__placeholder {
  color: #666;
}
.custom-select .select__dropdown-indicator::after {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  border-bottom: 1px solid #151817;
  border-left: 1px solid #151817;
  transform: translate(-35%, -50%) rotate(-45deg);
}
.select__control:hover {
  border: none !important;
  box-shadow: none !important;
  outline: none;
}

/* register */
.form-control-select {
  @apply font-medium;
}
.select__single-value {
  @apply !text-black;
}
.form-control-select .select__control {
  border: 1px solid #dbdbdb !important;
  min-height: 48px;
  padding: 0px 6px;
  box-shadow: none;
  font-size: 14px;
  border-radius: 10px;
}
.form-control-select .select__control:hover {
  border: 1px solid #dbdbdb !important;
}
.form-control-select .select__indicator-separator {
  display: none;
}
.form-control-select .select__control:focus {
  border: 1px solid #151817 !important;
  box-shadow: none;
}
.form-control-select .select__placeholder {
  font-size: 14px;
  color: #666;
}
.form-control-select .select__indicator {
  color: #6f7171;
}
.phone-select .select__control {
  border: none;
  min-height: 20px;
}
.phone-select .select__indicator-separator {
  display: none;
}
.phone-select .select__indicator {
  padding: 0;
}
.phone-select .form-control {
  border: none;
}
.phone-select .select__value-container {
  line-height: 15px;
  padding: 0px 8px;
}
.phone-select .select__control {
  border: none !important;
}
.phone-select .select__indicator {
  color: #6f7171;
}

.select__control {
  outline: none !important;
  box-shadow: none !important;
}

body {
  color: rgba(var(--default), 1);
}

.custom-placeholder .select__placeholder {
  color: #666;
}
.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.body-overflow-hidden {
  overflow: hidden;
}

html[dir='ltr'] .body-overflow-hidden {
  padding-right: 17px;
}

html[dir='rtl'] .body-overflow-hidden {
  padding-left: 17px;
}

@media (max-width: 1023.98px) {
  .newsdetail .logo {
    display: none;
  }
}
.select__option.select__option--is-focused,
.select__option:active {
  @apply bg-gray-100 !important;
}

.select__option.select__option--is-selected {
  @apply bg-gray-200 text-black !important;
}

.faq-list ul {
  list-style: disc;
  padding-left: 18px;
}
.faq-list ul li {
  padding: 5px 0px;
}
.faq-list p {
  padding-bottom: 10px;
}

.select__menu-list {
  @apply text-sm;
}
.react-datepicker-wrapper input {
  background-image: url('/images/calendar.svg');
  background-repeat: no-repeat;
}
html[dir='ltr'] .react-datepicker-wrapper input {
  background-position: right 15px center;
  padding-right: 42px;
}
html[dir='rtl'] .react-datepicker-wrapper input {
  background-position: left 15px center;
  padding-left: 42px;
}

.tab-underline a {
  @apply after:scale-0 text-muted cursor-pointer lg:font-medium font-semibold lg:pb-3.5 pb-2.5 w-full text-base text-center block relative after:absolute after:border-primary after:border-b-2 after:block after:h-0.5 after:-bottom-px after:left-0 after:right-0 after:transition after:duration-500 transition-all;
}
.tab-underline .active a {
  @apply after:scale-100 text-black;
}
.basis-0 {
  flex-basis: 0;
}

.toastr {
  @apply fixed bottom-[5%] left-0 right-0 mx-auto z-50 text-[15px] leading-none font-semibold lg:max-w-[500px] max-w-[95%] rounded-[10px] overflow-hidden shadow px-5 py-3.5;
}
.top-center .toastr {
  @apply top-[5%] bottom-auto;
}
.toastr > div {
  @apply flex items-center w-full;
}
.toastr .rrt-left-container {
  @apply w-5 h-5 bg-no-repeat ltr:mr-2.5 rtl:ml-2.5;
}
.toastr .rrt-left-container svg {
  @apply !w-[20px] fill-current;
}
.toastr .rrt-middle-container {
  @apply pt-[3px];
}
.toastr .rrt-right-container {
  @apply ltr:ml-auto rtl:mr-auto ltr:pl-3 rtl:pr-3;
}
.toastr.rrt-success {
  @apply text-[#24a121] bg-[#d6eed0];
}
.toastr.rrt-success .rrt-left-container {
  @apply bg-success-img;
}
.toastr.rrt-error {
  @apply text-[#ef3935] bg-[#fcebea];
}
.toastr.rrt-error .rrt-left-container {
  @apply bg-error-img;
}
.toastr .rrt-title,
.toastr .toastr-icon {
  @apply hidden;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  #registration-payment .container .row .col.span-md-12 {
    flex: 0 0 100% !important;
  }
}
.accent {
  color: #f9b514;
}
.closed {
  color: #00594f;
}
.danger {
  color: #ee3236;
}
.trim__text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (max-width: 639.98px) {
  #mobNotify + .Popup {
    max-width: 230px;
  }
}

.react-datepicker .react-datepicker__header {
  @apply bg-white;
}

.react-datepicker .react-datepicker__day {
  @apply rounded-full w-7 leading-7;
}

.react-datepicker .react-datepicker__day:hover {
  @apply bg-primary/10;
}

.react-datepicker .react-datepicker__day--selected,
.react-datepicker .react-datepicker__day--keyboard-selected {
  @apply bg-primary text-white;
}

.react-datepicker .react-datepicker__navigation {
  @apply border-0;
}

.react-datepicker .react-datepicker__navigation--previous {
  @apply border-r-primary;
}

.react-datepicker .react-datepicker__navigation--next {
  @apply border-l-primary;
}

.react-datepicker .react-datepicker__navigation-icon {
  @apply text-[11px];
}

.react-datepicker .react-datepicker__navigation-icon::before {
  @apply border-t-2 border-r-2 border-black rounded-[1px];
}

.react-datepicker__current-month {
  @apply pb-2;
}

.react-datepicker
  .react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle {
  @apply border-b-white;
}

.react-datepicker__month-dropdown-container--select,
.react-datepicker__year-dropdown-container--select {
  @apply mx-1;
}

.DocumentBlock .table-thead,
.DocumentBlock .tbody-tr {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.disable-number-scrolls::-webkit-inner-spin-button,
.disable-number-scrolls::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.disable-number-scrolls {
  -moz-appearance: textfield;
}

.CircularProgressbar {
  @apply rounded-full;
  box-shadow: 0px 4px 4px 0px #0000000f;
}
.CircularProgressbar-path {
  stroke: red;
}
.CircularProgressbar-trail {
  @apply stroke-[#E3E3E3];
}
.CircularProgressbar-text {
  @apply text-black font-semibold text-xl ltr:translate-x-[-17px] rtl:translate-x-[17px] translate-y-[7px];
}
.CircularProgressbar-background {
  fill: green;
}

.fill-primary {
  fill: rgba(var(--primary), 1);
}

.collapse-wrapper {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s ease-out;
}

.collapse-wrapper.is-open {
  grid-template-rows: 1fr;
}

.div-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.driving-scroll {
  @media (min-width: 1280px) {
    max-height: calc(100vh - 207px);
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    max-height: calc(100vh - 178px);
  }
  @media (max-width: 1023.98px) {
    max-height: 65vh;
  }
}

.driving-scroll::-webkit-scrollbar {
  @apply w-[5px];
}

.driving-scroll::-webkit-scrollbar-thumb {
  @apply rounded-2xl bg-[#F0F0F0];
}
.schedule-practical-wrapper .form-control-select .select__control {
  min-height: 44px;
}
.schedule-practical-wrapper .form-control {
  height: 44px;
}
.schedule-practical-wrapper .react-datepicker-wrapper input {
  background-image: url('../assets/images/calender.svg');
  background-repeat: no-repeat;
}
.schedule-practical-wrapper .react-datepicker-wrapper .icon-calendar {
  display: none;
}
.grid-cols-40px_1fr {
  grid-template-columns: 40px 1fr;
}
.grid-cols-auto_1fr {
  grid-template-columns: auto 1fr;
}

@media (min-width: 1024px) {
  .grid-cols-lg-1fr_auto {
    grid-template-columns: 1fr auto;
  }
}
@media (min-width: 768px) {
  .grid-cols-md-auto_1fr {
    grid-template-columns: auto 1fr;
  }
}
@media (max-width: 767.98px) {
  .refer-earn {
    @apply !bg-none;
  }
}
button {
  -webkit-tap-highlight-color: transparent;
}
.toastr{
  top: 5%;
  bottom: inherit !important;
}

.close-toastr {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;
  pointer-events: none !important;
}

