.custom-phone-input {
  .select__indicators {
    display: none;
  }
  .select__control {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    min-height: 27px;
  }
  .select__control {
    border: none !important;
  }
  .select__value-container {
    padding: 0 12px;
  }
  input {
    -webkit-appearance: none;
    background-color: transparent;
  }
}
